import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	ExpandableMenuItem,
	ExpandableMenuItemTrigger,
	ExpandableMenuItemContent,
} from '@atlassian/navigation-system/side-nav/expandable-menu-item';

import { AccessStatus, useSessionData } from '@confluence/session-data';
import { useRouteDataRef, useRouteUrl } from '@confluence/route-manager';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';

import { AppsIconComponent } from '../../GlobalNavigationIcons';
import { i18n } from '../../globalNavigationTranslations';
import type { GlobalItemProps } from '../globalItemProps';
import { useGlobalItemVisibility } from '../useGlobalItemVisibility';

import { AppsMenuContent } from './AppsMenuContent';
import { AppsMoreActionsMenu } from './AppsMoreActionsMenu';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../../analyticsCallbacks'))
			.fireAppsClickedAnalytics,
);

//in the future we may add the webItems query to the SSR preloads to determine the urls of the elements in the list instead of these patters
//however for now this ensures we dont' add any performance cost to every route
const selectedUrlPatterns = [
	//to the best of my knowledge these url patterns encompass everything that can appear inside the apps dropdown
	'/plugins/servlet/',
	'/mycalendar',
	'/marketplace',
	'/analytics',
	'/apps',
	'/admin/forge?',
];

export const AppsItem: FC<GlobalItemProps> = ({ isHidden, peekingId, setPeekingId }) => {
	const { accessStatus } = useSessionData();
	const isAdmin = accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;
	const isOnAppsRoute = useRouteUrl(onAppsSelector);

	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	const shouldHide = useGlobalItemVisibility(
		'apps',
		isOnAppsRoute,
		isHidden,
		peekingId,
		setPeekingId,
	);

	if (shouldHide) {
		return null;
	}

	const onClick = (
		_: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
		__: UIAnalyticsEvent,
		analyticsAttributes: { isExpanded: boolean },
	) => {
		const { isExpanded } = analyticsAttributes;
		void fireClickAnalytics(isExpanded);
	};

	return (
		<ExpandableMenuItem isDefaultExpanded={isOnAppsRoute} isChildSelected={isOnAppsRoute}>
			<ExpandableMenuItemTrigger
				elemBefore={AppsIconComponent}
				actionsOnHover={isAdmin && <AppsMoreActionsMenu />}
				onClick={onClick}
			>
				<FormattedMessage {...i18n.apps} />
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<AppsMenuContent />
			</ExpandableMenuItemContent>
		</ExpandableMenuItem>
	);
};

const onAppsSelector = {
	selector: (routeUrl: string | undefined) => {
		if (!routeUrl) {
			return false;
		}
		const lowerUrl = routeUrl.toLowerCase();
		return selectedUrlPatterns.some((urlPattern) => lowerUrl.includes(urlPattern));
	},
};
