import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import Skeleton from '@atlaskit/skeleton';
import MarketplaceIcon from '@atlaskit/icon/core/marketplace';
import { Box, Stack } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';
import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import {
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system/side-nav/menu-section';

import type { useWebItemLocationWebItemType as WebItem } from '@confluence/web-item-location';
import { SYSTEM_HEADER_LEFT, useWebItemLocation } from '@confluence/web-item-location';
import { ErrorDisplay } from '@confluence/error-boundary';
import { CONTEXT_PATH, GLOBAL_PAGE_FORGE, SITE_ANALYTICS } from '@confluence/named-routes';
import { useLocation } from 'react-router-dom';
import type { ForgeUIGlobalPageExtensionType } from '@confluence/forge-ui';
import { extensionTitle, FORGE_MODULE_GLOBAL_PAGE, useExtensionList } from '@confluence/forge-ui';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';

import {
	ANALYTICS_KEY,
	ANALYTICS_KEY_MODERATE,
	AUTOMATION_KEY,
	DISCOVER_KEY,
	PEOPLE_KEY,
	SPACES_KEY,
} from './appKeys';
import { AppIcon } from './AppIcon';

const DISCOVER_URL = `${CONTEXT_PATH}/marketplace`;

const NOT_ALLOWED_WEB_ITEMS = [SPACES_KEY, PEOPLE_KEY, DISCOVER_KEY];

export const AppsMenuContent = () => {
	const { edition, accessStatus } = useSessionData();
	const hideAutomation =
		edition !== 'PREMIUM' || accessStatus !== AccessStatus.LICENSED_ADMIN_ACCESS;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireAppsNavigationItemClicked = (itemId: string | null) => () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'appsNavigationMenuItem',
				source: 'globalNavigation',
				attributes: {
					navigationLayer: 'global',
					navigationContainer: 'sidebar',
					navVersion: '4',
					componentType: 'customItem',
					itemType: 'app',
					itemId,
					section: 'yourApps',
				},
			},
		}).fire();
	};

	const {
		webItems,
		loading: webItemsLoading,
		error: webItemsError,
	} = useWebItemLocation({
		location: SYSTEM_HEADER_LEFT,
		notAllowedWebItems: [...NOT_ALLOWED_WEB_ITEMS, hideAutomation ? AUTOMATION_KEY : ''],
	});
	const location = useLocation();

	const {
		extensions,
		loading: extensionsLoading,
		error: extensionsError,
	} = useExtensionList({
		moduleType: FORGE_MODULE_GLOBAL_PAGE,
	});

	const loading = webItemsLoading || extensionsLoading;
	const hasApps = webItems.length > 0 || extensions.length > 0;
	const displayApps = hasApps && !loading;

	return (
		<>
			<MenuSection>
				{displayApps && (
					<MenuSectionHeading>
						<FormattedMessage {...i18n.yourApps} />
					</MenuSectionHeading>
				)}
				<MenuList>
					{displayApps && (
						<AppItems
							webItems={webItems}
							extensions={extensions}
							pathname={location?.pathname}
							fireAppsNavigationItemClicked={fireAppsNavigationItemClicked}
						/>
					)}
					{loading && <LoadingSkeletons />}
					<MenuLinkItem
						isSelected={location?.pathname?.includes(DISCOVER_URL)}
						href={DISCOVER_URL}
						elemBefore={<MarketplaceIcon label="" color="currentColor" />}
						onClick={fireAppsNavigationItemClicked('exploreApps')}
					>
						{loading || hasApps ? (
							<FormattedMessage {...i18n.exploreMoreApps} />
						) : (
							<FormattedMessage {...i18n.exploreApps} />
						)}
					</MenuLinkItem>
				</MenuList>
			</MenuSection>
			<ErrorFlag webItemsError={webItemsError} extensionsError={extensionsError} />
		</>
	);
};

type AppItemsProps = {
	webItems: WebItem[];
	extensions: ForgeUIGlobalPageExtensionType[];
	pathname?: string;
	fireAppsNavigationItemClicked: (itemId: string | null) => () => void;
};

const AppItems = ({
	webItems,
	extensions,
	pathname,
	fireAppsNavigationItemClicked,
}: AppItemsProps) => (
	<>
		{webItems.map(({ id, url, label, icon, completeKey }) => {
			const href = fixWebItemUrl(url, completeKey);
			const isSelected = (pathname && pathname?.includes(href)) || false;
			return (
				<MenuLinkItem
					key={id}
					href={href}
					elemBefore={<AppIcon url={icon?.path} completeKey={completeKey} />}
					isSelected={isSelected}
					onClick={fireAppsNavigationItemClicked(completeKey)}
				>
					{label}
				</MenuLinkItem>
			);
		})}
		{extensions.map((extension) => (
			<MenuLinkItem
				key={extension.key}
				href={GLOBAL_PAGE_FORGE.toUrl({
					appId: extension.appId,
					envId: extension.environmentId,
					forgeManifestRoute: extension.properties.route,
				})}
				elemBefore={<AppIcon url={extension.properties.icon} />}
			>
				{extensionTitle(extension)}
			</MenuLinkItem>
		))}
	</>
);

const LoadingSkeletons = () => (
	<Box paddingBlockStart="space.100" testId="loading">
		<Stack space="space.100">
			<Skeleton width="100%" height="20px" borderRadius="3px" />
			<Skeleton width="100%" height="20px" borderRadius="3px" />
			<Skeleton width="100%" height="20px" borderRadius="3px" />
		</Stack>
	</Box>
);

const ErrorFlag = withFlags(
	({
		webItemsError,
		extensionsError,
		flags,
	}: {
		webItemsError: Error | undefined;
		extensionsError: Error | undefined;
	} & WithFlagsProps) => {
		useEffect(() => {
			if (webItemsError || extensionsError) {
				void flags.showFlag({
					title: <FormattedMessage {...i18n.errorTitle} />,
					description: <FormattedMessage {...i18n.errorDescription} />,
					type: 'error',
					close: 'auto',
				});
			}
		}, [webItemsError, extensionsError, flags]);

		return (
			<>
				{webItemsError && <ErrorDisplay error={webItemsError} />}
				{extensionsError && <ErrorDisplay error={extensionsError} />}
			</>
		);
	},
);

const absoluteUrlCheck = new RegExp('^(?:[a-z]+:)?//', 'i');

const fixWebItemUrl = (url: string | null, completeKey: string | null): string => {
	if (!url) {
		return '#';
	}

	if (completeKey === ANALYTICS_KEY || completeKey === ANALYTICS_KEY_MODERATE) {
		return SITE_ANALYTICS.toUrl();
	}

	if (absoluteUrlCheck.test(url)) {
		return url;
	}

	if (url.startsWith(CONTEXT_PATH)) {
		return url;
	}

	return `${CONTEXT_PATH}${url}`;
};

const i18n = defineMessages({
	yourApps: {
		id: 'side-navigation.global.apps.your-apps',
		defaultMessage: 'Your apps',
		description: 'Label for the section of the global navigation that displays apps',
	},
	exploreApps: {
		id: 'side-navigation.global.apps.explore-apps',
		defaultMessage: 'Explore apps',
		description:
			'Label for the link to find apps. Appears in global navigation when there are no apps installed yet.',
	},
	exploreMoreApps: {
		id: 'side-navigation.global.apps.explore-more-apps',
		defaultMessage: 'Explore more apps',
		description:
			'Label for the link to find apps. Appears in global navigation, underneath already installed apps.',
	},
	errorTitle: {
		id: 'side-navigation.global.apps.error-title',
		defaultMessage: 'We couldn’t load apps',
		description: 'Title for the error flag when fetching apps fails',
	},
	errorDescription: {
		id: 'side-navigation.global.apps.error-description',
		defaultMessage: 'Refresh and try again.',
		description: 'Description for the error flag when fetching apps fails',
	},
});
