import React from 'react';

import DefaultAppIcon from '@atlaskit/icon/core/app';
import AutomationIcon from '@atlaskit/icon/core/automation';
import CalendarIcon from '@atlaskit/icon/core/calendar';
import ChartIcon from '@atlaskit/icon/core/chart-trend';

import { AUTOMATION_KEY, CALENDAR_KEY, ANALYTICS_KEY, ANALYTICS_KEY_MODERATE } from './appKeys';

const iconOverrides = {
	[AUTOMATION_KEY]: AutomationIcon,
	[CALENDAR_KEY]: CalendarIcon,
	[ANALYTICS_KEY]: ChartIcon,
	[ANALYTICS_KEY_MODERATE]: ChartIcon,
};

type AppIconProps = {
	url: string | undefined;
	completeKey?: string | null;
};

export const AppIcon = ({ url, completeKey }: AppIconProps) => {
	if (completeKey) {
		const IconOverride = iconOverrides[completeKey];
		if (IconOverride) {
			return <IconOverride />;
		}
	}

	if (!url) {
		return <DefaultAppIcon label="" color="currentColor" />;
	}

	return (
		<svg viewBox="0 0 16 16" width="16" height="16">
			<defs>
				<filter id="changeFillColor">
					<feColorMatrix
						type="matrix"
						values="0.02, 0, 0, 0, 0, 0, 0.04, 0, 0, 0, 0, 0, 0.07, 0, 0, 0, 0, 0, 1, 0"
					/>
				</filter>
			</defs>
			{/* eslint-disable-next-line no-restricted-syntax */}
			<image
				xmlnsXlink="http://www.w3.org/1999/xlink"
				xlinkHref={url}
				width="16"
				height="16"
				x="0"
				y="0"
				filter={isSvg(url) ? 'url(#changeFillColor)' : ''}
			/>
		</svg>
	);
};

const isSvg = (url: string) => {
	const fileType = url.split(/\#|\?/)[0].split('.').pop();
	return !!fileType && fileType.trim() === 'svg';
};
